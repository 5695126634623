const Layout = {
  EDGE_PADDING: 16,
  INNER_PADDING: 8,
  OUTER_PADDING: 16,
  ELEMENT_PADDING: 8,
  ICON_GAP: 8,
  BORDER_RADIUS: 2,
  MAIN_TITLE: 64,
  TITLE: 24,
  HEADER: 18,
  TEXT: 16,
  SPAN: 14,
}

export default Layout
