const Text = {
  FONT: '"Cereal", "Arial", sans-serif',
  MAIN_TITLE: 64,
  TITLE: 24,
  HEADER: 18,
  TEXT: 16,
  SPAN: 14,
  LABEL: 12,
}

export default Text
